import React, { useContext, useMemo } from 'react';
import * as Components from './index';
import * as Parsers from '../parsers';
import Context from '../Context';

const buildProps = (props, data) => {
  const result = {};

  props?.forEach(item => {
    result[item.key] = ['array'].includes(item.type) ? JSON.stringify(item.value).replace(/"/g, '"') : item.parser ? Parsers[item.parser](item.value, data) : item.value;
  });

  return result;
};

export const buildElement = (elements, child, data = {}) => {

  const elem = elements.find(item => item.id === child.id);

  if (!elem) return '';
  if (elem.element || elem.component) {
    const Component = elem.element || Components[elem.component];

    /**
     * FIXME
     * Gambiarra para pegar o value dos campos direto da ContextApi
     */
    const propsName = elem.props?.find(item => item.key === 'name');
    const pogProps = propsName && data && data[propsName.value] ? { value: data[propsName.value] } : {};
    const errorProps = propsName && data?.error?.fields ? { error: data?.error?.fields[propsName.value] } : {};
    const allProps = {
      ...buildProps(elem.props, data),
      ...pogProps,
      ...errorProps,
      ...(child.slot ? { slot: child.slot } : {}),
    };
    /** FIM DA GAMBIARRA */

    return (
      <Component key={child.id} {...allProps}>
        {elem.children?.map(item => item.id ? buildElement(elements, item, data) : item)}
      </Component>
    );
  } else if (elem.content) {
    return elem.content;
  }
};

const Render = ({ config }) => {
  const { data } = useContext(Context);
  const memoBuildElement = useMemo(() => {
    return buildElement(config?.elements || [], { id: config?.starts }, data || {});
  }, [config, data]);

  return config && config.elements?.length > 0 ? memoBuildElement : <div>Loading...</div>;
};

export default Render;
