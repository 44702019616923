import React, { useContext, useState } from 'react';
import { executeCustomAction, mountBody, sendCustomEvent } from '../utils';
import Context from '../Context';

const Form = ({ action, method, children, success, error, ...others }) => {
  const [formResponse, setFormResponse] = useState(null);
  const { addData, setLoading } = useContext(Context);

  const onSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const reqBody = {};

    Object.keys(e.target.elements).forEach(field => {
      const element = e.target.elements[field];

      if (element.type !== 'submit') {
        mountBody(reqBody, element);
      }
    });

    const response = await executeCustomAction(action, method, reqBody);

    if (!response || response.type === 'Error') {
      setFormResponse({ type: 'error', message: response.message || 'Houve um erro inesperado!' });
      addData({ error: response });
      // TODO - Remover: O compoente de feedback deve ter a habilidade de sair sozinho
      setTimeout(() => {
        setFormResponse(null);
      }, 6000);

      if (error) {
        sendCustomEvent(error, { id: others['error-state'] });
      }
    } else {
      addData(response?.data);
      sendCustomEvent(success, { id: others['success-state'] });
    }
    setLoading(false);
  };

  return (
    <>
      <form action={action} method={method} onSubmit={e => onSubmit(e)}>
        {children}
      </form>

      {formResponse && <dwui-toaster type={formResponse.type} message-title="Ops!" message-text={formResponse.message}></dwui-toaster>}
    </>
  );
};

export default React.memo(Form);
