/**
 * Substitui em uma striong os valores chaveados por seu semelhante no contexto da aplicação
 * @param {String} str
 * @param {object} data Espelho do estado da aplicação (Context Api)
 */
export const context = (str, data) => {
  let result = str;
  const regExp = /\{([^)]+)\}/g;
  const matches = str.match(regExp);

  matches
    .map(item => item.replace('{', '').replace('}', ''))
    .forEach(v => {
      result = result.replaceAll(`{${v}}`, data[v]);
    });
  return result;
};
