/**
 * Executa uma chamada especifica de captura de configuração de componentes
 * @param {string} id
 * @returns
 */
export const getConfig = async id => {
  const res = await fetch(`${process.env.REACT_APP_CONFIG_LOCATION}/${id}.json`);
  return await res.json();
};

/**
 * Monta as chaves (em array ou objetos) do body da requisição com base no name do input
 */
export const mountBody = (reqBody, element) => {
  const name = element.name;
  const isArray = name?.includes('[]');
  let value = element.getValue ? element.getValue().raw : element.value;

  if (element.type === 'checkbox' && element.value === 'on') {
    value = element.checked;
  }

  if (!name) {
    return reqBody;
  }

  if (isArray) {
    const arrayName = name.split('[]')[0];
    const keyName = name.split('[]')[1];

    if (!reqBody[arrayName]) {
      reqBody[arrayName] = [];
    }

    if (keyName) {
      const obj = { [keyName]: value };
      /**
       * TODO
       * alterar `reqBody[arrayName][0]` para contemplar mais indíces
       * exemplo esperado: `reqBody[arrayName][index]`
       */
      reqBody[arrayName][0] = { ...reqBody[arrayName][0], ...obj };
    } else if (element.checked && !reqBody[arrayName].includes(value)) {
      reqBody[arrayName].push(value);
    }
  } else {
    reqBody[name] = value;
  }

  return reqBody;
};

export const executeCustomAction = async (url, method, body) => {
  try {
    const fullURL = `${url}${method === 'GET' ? `?${new URLSearchParams(body).toString()}` : ''}`;
    const response = await fetch(fullURL, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(!['GET', 'HEAD'].includes(method) && body ? { body: JSON.stringify(body) } : {}),
    });

    return await response.json();
  } catch (error) {
    console.error('Erro:', error);
    const errorData = {
      error: { message: 'Erro ao processar a solicitação' },
    };
    return errorData;
  }
};

/**
 * Dispara um evento personalizado (CustomEvent) na raiz da janela do navegador
 * @param {string} eventName Nome do evento
 * @param {object} eventData Dados incluidos nos detalhes do evento personalizado
 */
export const sendCustomEvent = (eventName, eventData) => {
  return document.dispatchEvent(
    new CustomEvent(eventName, {
      detail: eventData,
    }),
  );
};
