import { useLoaderData } from "react-router-dom";
import Render from "../components/Render";

const ViewPage = () => {
  const config = useLoaderData();

  return <Render config={config} />;
};

export default ViewPage;
