import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { getConfig } from "./utils";
import { ViewPage } from "./pages";

const routeBase = {
  element: <ViewPage />,
  loader: async ({ params }) => {
    return await getConfig(params.formid || 'psicologia');
  },
}

const router = createBrowserRouter([
  {
    path: "/",
    ...routeBase
  }, {
    path: "/:formid",
    ...routeBase
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
