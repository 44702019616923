import { useContext, useEffect, useMemo, useState } from "react";
import { buildElement } from "./Render";
import { getConfig } from "../utils";
import Context from "../Context";

const State = ({ id, listen }) => {
  const [config, setConfig] = useState({ elements: [] });
  const { data, loading, setLoading } = useContext(Context);

  const memoBuildElement = useMemo(
    () => buildElement(config.elements, { id: config.starts }, data || {}),
    [config, data]
  );

  const handleConfig = (key) => {
    if (id && !loading) {
      setLoading(true);
      getConfig(key)
        .then((response) => {
          setConfig(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleFormEvent = (event) => {
    if (event?.detail?.id) {
      handleConfig(event.detail.id);
    }
  };

  useEffect(() => {
    handleConfig(id);
    document.addEventListener(listen, handleFormEvent);

    // return document.removeEventListener(listen, handleFormEvent);
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{position:"relative"}}>
      {loading && <div style={{position:"absolute", height: "100%", opacity:"0.7", backgroundColor:"#fff", width: "100%", zIndex: 99}}></div>}
      {config && config.elements?.length > 0 ? (
        memoBuildElement
      ) : (
        <div>Carregando...</div>
      )}
      </div>
  );
};

export default State;
