import { createContext, useState } from 'react';

const INITIAL_DATA = {
  loading: false,
  data: {
  },
};
const Context = createContext(INITIAL_DATA);

export const ContextProvider = ({ children }) => {
  const [data, setData] = useState(INITIAL_DATA.data);
  const [loading, setLoading] = useState(INITIAL_DATA.loading);

  const addData = newData => {
    setData({
      ...data,
      ...newData,
    });
  };

  const deleteData = keys => {
    const oldData = { ...data };
    keys?.forEach(item => {
      if (oldData[item]) {
        delete oldData[item];
      }
    });

    setData(oldData);
  };

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        data,
        deleteData,
        addData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
